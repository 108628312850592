const it = {
    msgAppName: "Woodigital",

    actionGetStart: "Inizia",
    actionDetails: "Dettagli",
    actionLogin: "Accedi",
    actionRegister: "Iscriviti",
    actionLogout: "Logout",
    actionSend: "Invia",
    actionGoTest: "Vai al test",
    actionSeeCert: "Vedi i certificati",
    actionNextPill: "Vai alla prossima pillola",
    actionNewThread: "Nuovo argomento",
    actionBack: "Indietro",
    actionComment: "Commento",
    actionUpdate: "Aggiornamento",
    actionChangePass: "Cambia password",
    actionAddAnnouncement: "Aggiungi messaggio",
    actionStartTest: "Inizia il test",
    actionSubmit: "Invia",
    actionClose: "Chiudi",
    actionEdit: "Modifica",
    actionDelete: "cancella",
    actionShow: "Mostra",
    actionNext: "Prossimo",
    actionDone: "Completato",
    actionOpenResource: "Apri risorsa",
    actionDownloadInfo: "Scarica il manuale utente",
    actionCourseCertificate: "Certificato di corso",

    msgWelcome: "Benvenuto",
    msgBackToUnit: "Torna all'unità di apprendimento",
    msgExploreUnits: "Scopri il corso di formazione online WOODigital",
    msgExploreUnitsText: "La progressiva digitalizzazione dell'industria manifatturiera, lo sviluppo dei big data, la produzione di arredi e mobili connessi a Internet e i materiali avanzati stanno oggi rimodellando l'industria del legno-arredo a livello europeo. Le aziende europee sono alla ricerca di operatori del legno digitalmente competenti, che abbiano familiarità con le funzionalità dei principi dell'Industria 4.0. Il corso di formazione WOODigital offre possibilità di perfezionamento uniche che ti consentono di diventare uno dei WOODWORKER 4.0 digitalmente competenti.",
    msgLearningUnits: "Unità di apprendimento",
    msgLatestPills: "Pillole raccomandate",
    msgLatestPillsText: "Scopri le Pillole formative raccomandate dai nostri Partner europei",

    msgPills: "Pillole",
    msgPartners: "Partner",
    msgNextLearning: "Prossima unità di apprendimento",

    msgExamAlert: "Al termine di tutte le pillole formative, gli studenti possono completare un <b>test di auto-valutazione a risposta multipla</b> per ottenere il <b>certificato e il badge digitale.</b> E' richiesto almeno il <b>75% di risposte esatte</b> per superare l'Unità di apprendimento.",
    msgAssignment: "Compito",
    msgForStudents: "Ad uso dei manager",
    msgForTeachers: "Ad uso dei docenti",
    msgProgressionOptions: "Opzioni di avanzamento",
    msgCompetencesUnitTitle: "Completando queste pillole, gli studenti saranno in grado di:",

    msgCompetencesTitle: "Completando queste pillole, gli studenti saranno in grado di:",
    msgTopics: "Argomenti",
    msgHowApply: "Applicazione pratica della conoscenza acquisita",
    msgResources: "Risorse di apprendimento",
    msgResourcesSubtitle: "Leggi tutte le seguenti risorse prima di passare all'auto-valutazione.",

    msgStartTestSubtitle: "**Completando tutte le pillole formative dell'Unità di apprendimento**",


    msgWooLearningPlatform: "Piattaforma di apprendimento WOODigital",
    msgWooLearningPlatformText: "La piattaforma di apprendimento WOODigital è dedicata alle persone ed alle istituzioni interessate a sviluppare l'innovazione digitale ed a beneficiare dell'opportunità di un percorso formativo di qualità.<br/><br/> La piattaforma si rivolge alla nuova generazione di studenti e professionisti europei ma anche agli istituti di istruzione e formazione professionale, agli organismi di ricerca, alle imprese e fornisce loro abilità e competenze richieste dal mercato del lavoro dell'industria del legno-arredo, soggetta alla trasformazione derivante dai processi di digitalizzazione. Raccomandiamo fortemente questo percorso formativo per:",

    msgStudents: "Studenti",
    msgEducators: "Docenti",
    msgCompanies: "Aziende & Associazioni",
    msgProfessionals: "Professionisti / Inoccupati",

    msgStudentsText: "Per i futuri operatori del legno che prima di entrare nel mercato del lavoro desiderano acquisire le necessarie competenze digitali ed assicurarsi una conoscenza aggiornata del settore legno-arredo a livello europeo.",
    msgEducatorsText: "Per i centri di istruzione e formazione professionale e per gli enti formativi rivolti ai futuri operatori del legno, interessati ad offrire un percorso formativo innovativo e di qualità.",
    msgCompaniesText: "Per le aziende del settore legno-arredo che desiderano aggiornare le competenze digitali dei loro collaboratori.",
    msgProfessionalsText: "Per coloro che cercano opportunità per sviluppare competenze professionali specifiche e/o aprirsi nuovi percorsi di carriera.",

    msgContact: "Contatti",
    msgHome: "Home",
    msgForum: "Forum",
    msgProfile: "Profilo",

    msgAnnouncements: "Messaggi",
    msgAnnouncementsFinished: "Discussioni chiuse",

    msgThreads: "Argomenti",
    msgOpen: "Apri",
    msgClose: "Chiudi",
    msgLeaveComment: "Lascia un commento",

    msgTeacherProfile: "Profilo del docente",
    msgUserProfile: "Profilo utente",
    msgUserInfo: "Informazioni sull'utente",

    msgRegisterText: "Registrati ed scopri il percorso formativo online WOODigital.",

    msgName: "Nome e Cognome",
    msgEmail: "Email",
    msgCountry: "Paese",
    msgAge: "Età",
    msgGender: "Genere",
    msgOrganization: "Organizzazione",
    msgForgetPass: "Hai dimenticato la password?",

    msgPassword: "Password",
    msgPassInfo: "Cambia la tua password",
    msgCurrentPass: "Password attuale",
    msgNewPassword: "Nuova password",

    msgNewAnnouncement: "Nuovo messaggio",
    msgNewAnnouncementInfo: "Pubblica un nuovo messaggio",
    msgAnnouncesInfo: "Elenco dei miei messaggi",


    msgMyCertificates: "I miei certificati",
    msgMyCertificatesInfo: "Elenco di tutti i miei certificati",

    msgDatenEnd: "Data di conclusione",
    msgText: "Testo",
    msgWriteText: "Scrivi il testo",

    msgTitle: "Titolo",

    msgTerms: "Accetta i termini & le condizioni",
    msgCopyright: "Copyright&copy;2021 WOODIGITAL. Tutti i diritti riservati",

    msgProyectOwner: "UN PROGETTO DI",
    msgFooterContact: "CONTATTACI",
    msgFooterNews: "LA NOSTRA NEWSLETTER",
    msgFooterLegal: "Note legali",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",
    msgFooterEu: "Il sostegno della Commissione europea alla produzione di questa pubblicazione non costituisce un'approvazione del contenuto, che riflette esclusivamente il punto di vista degli autori, e la Commissione non può essere ritenuta responsabile per l'uso che può essere fatto delle informazioni ivi contenute.",
    msgFooterGr: "Grant Agreement: 2020-1-FR01-KA202-080104",
    msgFooterLinkedIn: 'GRUPPO LINKEDIN',

    msgSendSuccess: "Operazione conclusa con successo",

    errorOblData: "Completa i campi richiesti",
    errorLogin: "Email/password errata",
    errorRegister: "",
    errorSend: "Errore, verifica le informazioni inserite",

    msgNotPass: "Non hai superato il test. Rileggi la lezione e prova di nuovo.",
    msgCongratulations: "Congratulazioni !! Hai superato il test. Il tuo certificato è disponibile nella sezione Profilo.",

    /* CATEGORIE DI UTENTI */
    msgStudent: "Studente",
    msgVetProvider: "Ente di istruzione e formazione professionale",
    msgCompany: "Azienda",
    msgOther: "Altro",

    /* GENERE */
    msgFemale: "Femminile",
    msgMale: "Maschile",
    msgNonBinary: "Non-binary",
    msgTransgender: "Transgender",
    msgIntersex: "Intersex",
    msgLetme: "Preferisco autodefinirmi…",
    msgNotSay: "Preferisco non dirlo",

    /* PAESE */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default it;