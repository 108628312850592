import { mdiSchoolOutline, mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Fragment } from 'react/cjs/react.production.min';
import MessageItem from '../components/MessageItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { Container, ContainerFlex, Card, Button, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { getStorageUser } from '../components/util/UserUtil';

const ThreadDetail = () => {
    const [item, setItem] = useState();

    const [isSending, setIsSending] = useState(false);
    const [formData, setFormData] = useState("");
    const [messages, setMessages] = useState([])
    const languageContext = useContext(LanguageContext);

    let history = useHistory();
    let params = useParams();

    useEffect(() => getItem(), [params.id])

    const getItem = () => HttpConnection(`${HttpOperations.threads}/${params.id}`, result => {
        setItem(result.item)
        setMessages([...result.item.messages]);
    }, error => console.log(error), HttpMethod.get);

    const pushMessage = () => {
        if (!isSending) {
            setIsSending(true)
            HttpConnection(`${HttpOperations.threads}/${params.id}/message`,
                result => {
                    getItem();
                    setFormData("");
                    setIsSending(false);
                },
                error => console.log(error), HttpMethod.post, { text: formData });
        }
    }

    const closeThread = () => HttpConnection(`${HttpOperations.threads}/${params.id}`, result => getItem(),
        error => console.log(error), HttpMethod.put, { title: item.title, text: item.text, thread_state_id: 2 });

    return (
        <Container width="12" justify="center" minheight="100vh">
            <ContainerFlex width="12" px="16" justify="center">
                <ContainerFlex width="12" lg="8" pv="8">
                    <ContainerFlex width="12" pv="8" align="flex-end">
                        <Container width="wrap" fontColor="color-blue-dark" fontWeight="300" fontSize="48px" children={item ? item.title : ""} />
                        <Button onClick={() => history.goBack()} pv="4" ph="16" mh="8" mb="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionBack" />} />
                    </ContainerFlex>
                    {
                        item ?
                            <Fragment>
                                <ContainerFlex width="12" justify="space-between" align="center" pb="16" fontSize="14px">
                                    <Container width="12" mb="16" fontWeight="100" children={item.text} />

                                    <ContainerFlex align="center">
                                        <Card flex pv="4" ph="8" radius="20px" align="center" border="solid" borderWidth="2px" borderColor="color-blue">
                                            <Icon style={{ margin: "0 4px 0 0" }} path={item.state.id === 1 ? mdiCheckboxBlankCircleOutline : mdiCheckboxMarkedCircle} size={1} />
                                            <TextLocalized children={item.state.id === 1 ? "msgOpen" : "msgClose"} />
                                        </Card>
                                        <Container mh="8" fontWeight="100" children={item.owner.name} />
                                        {
                                            item.owner.role.id === 3
                                                ? <Icon style={{ margin: "0 8px" }} path={mdiSchoolOutline} size={"12px"} />
                                                : null
                                        }
                                        <Container mh="8" fontWeight="100" children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                                    </ContainerFlex>
                                    {
                                        getStorageUser().id === item.owner.id && item.state.id === 1 ?
                                            <ContainerFlex>
                                                <Button onClick={closeThread} mx="8" pv="8" ph="16" fontColor="color-white" fontWeight="300" color="color-red" hoverColor="color-red-dark" children={<TextLocalized children="actionClose" />} />
                                            </ContainerFlex>
                                            : null
                                    }
                                </ContainerFlex>

                                <Container width="12" height="1px" color="color-blue-dark" />
                                <ContainerFlex width="12" pv="16" children={messages.map((item, index) => <MessageItem key={index} item={item} />)} />
                                {
                                    item.state.id === 1 ?
                                        <Fragment>
                                            <Container width="12" height="1px" color="color-blue-dark" />

                                            <Card width="12" mt="24" border="solid" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
                                                <textarea onChange={e => setFormData(e.target.value)} value={formData} placeholder={languageContext.dictionary["msgLeaveComment"]} rows="5" style={{ padding: "16px" }} />
                                            </Card>

                                            <ContainerFlex width="12" justify="flex-end" pb="16" fontSize="14px">
                                                <Button onClick={pushMessage} pv="8" ph="16" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionComment" />} />
                                            </ContainerFlex>
                                        </Fragment>
                                        : null
                                }
                            </Fragment>
                            : null
                    }
                </ContainerFlex>
            </ContainerFlex>
        </Container>
    );
};

export default ThreadDetail;