import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CompetenceItem from '../components/CompetenceItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import ResourceItem from '../components/ResourceItem';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import TopicItem from '../components/TopicItem';
import { HttpConnection, HttpMethod, HttpOperations, imageURL, serverUrlEndpoint } from '../components/util/HttpConnection';

const PillDetail = () => {
    const [item, setItem] = useState();
    const history = useHistory();
    const params = useParams();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.pills}/${params.id}?lang=${language.id}`, result => setItem(result.item), error => console.log(error), HttpMethod.get)
    }, [params.id, language]);

    const nextPill = () => {
        let index = item.unit.pills.findIndex(pill => pill.id === item.id)
        history.push(`/pill/${item.unit.pills[index + 1].id}`)
    }

    const openDocs = (item) => {
        window.open(`${serverUrlEndpoint}${HttpOperations.docs}/${item}`);
    }

    return (
        <Container width="12" minheight="calc(100vh - 64px)">
            {
                item ?
                    <Fragment>

                        <ContainerFlex width="12" justify="center">
                            <ContainerFlex width="12" lg="8" pv="32" justify="center" align="center">
                                <ContainerFlex width="12" ph="16" justify="flex-start" >
                                    <Button onClick={() => history.push(`/unit/${item.unit.id}`)} flex align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" ph="16" pv="8" radius="10px">
                                        <Icon path={mdiChevronLeft} size={1} />
                                        <ContainerFlex cursor="pointer" ph="8" fontSize="18px" fontWeight="300" justify="center" align="center" children={<TextLocalized children="msgBackToUnit" />} />
                                    </Button>
                                </ContainerFlex>
                                <Container width="12" lg="6">
                                    <Container width="12" ph="16" fontWeight="bold" fontSize="74px" fontColor="color-black" children={item.content.title} />
                                    <Container width="12" ph="16" pv="16" fontWeight="300" fontSize="18px" fontColor="color-black" children={item.content.summary} />
                                </Container>
                                <ContainerFlex width="0" lg="6" justify="flex-end" align="cener">
                                    <Card idealwidth="300px" height="300px" image={imageURL(item.image)} radius="50%" />
                                </ContainerFlex>
                            </ContainerFlex>

                            <ContainerFlex width="12" justify="center" mt="16" mb="64" ph="16">
                                <Card width="12" lg="8" pv="28" textAlign="center" border="solid" borderColor="color-black" borderWidth="1px">
                                    <Container width="12" textAlign="center" pv="8" ph="16" fontWeight="600" children={<TextLocalized children="msgCompetencesTitle" />} />
                                    {item.content.competences.map((item, index) => <CompetenceItem key={index} item={item} />)}
                                </Card>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex width="12" color="color-blue" justify="center">
                            <ContainerFlex width="12" lg="8" ph="16" mv="64" justify="center" align="center">
                                <ContainerFlex width="12" justify="center" align="center" mb="16" fontWeight="bold" fontSize="46px" fontColor="color-white" children={<TextLocalized children="msgTopics" />} />

                                <ContainerFlex width="12" pv="32" fontColor="color-white" fontSize="18px" fontWeight="50" children={item.content.topics.map((item, index) => <TopicItem key={index} item={item} index={index} />)} />

                                {
                                    item.content.key_resource_type_id === 2 ?
                                        <Card flex width="12" height="400px" justify="center" align="center" border="solid" borderWidth="1px" borderColor="color-white" image={imageURL(item.image)} radius="10px">
                                            <Button onClick={() => openDocs(item.content.key_resource)}
                                                color="color-black" hoverColor="color-blue-dark" fontColor="color-white"
                                                fontWeight="300" fontSize="18px" mh="16" pv="16" ph="32"
                                                children={<TextLocalized children="actionOpenResource" />} />
                                        </Card>
                                        :
                                        <ContainerFlex width="12" justify="space-around">
                                            <iframe width="100%" height="600" src={item.content.key_resource} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </ContainerFlex>

                                }
                            </ContainerFlex>
                        </ContainerFlex>
                        {
                            item.content.how_apply ?
                                <ContainerFlex width="12" color="color-white" justify="center" align="flex-start">

                                    <ContainerFlex width="12" lg="8" mt="64" justify="center" align="flex-start">
                                        <ContainerFlex width="12" lg="12" ph="16" align="center" fontWeight="bold" fontSize="46px" children={<TextLocalized children="msgResources" />} />
                                        <Container width="12" ph="16" pb="32" fontWeight="300" fontSize="18px" fontColor="color-black" children={<TextLocalized children="msgResourcesSubtitle" />} />
                                        <ContainerFlex width="12" order="2" lg="12" ph="16" fontSize="18px" fontWeight="50">
                                            {item.content.resources.map((item, index) => <ResourceItem key={index} item={item} index={index} />)}
                                        </ContainerFlex>
                                    </ContainerFlex>

                                    <ContainerFlex width="12" lg="8" mt="64" justify="center" align="flex-start">
                                        <ContainerFlex width="12" lg="12" ph="16" align="center" fontWeight="bold" fontSize="46px" fontColor="color-black" children={<TextLocalized children="msgHowApply" />} />
                                        <Container width="12" lg="12" ph="16">
                                            <Card flex width="12" px="16" mv="16" color="color-white" border="solid" borderWidth="1px" borderColor="color-blue">
                                                <Container width="12" lg="6">
                                                    <pre style={{ fontFamily: "Poppins", margin: "0px", maxWidth: "100%", whiteSpace: "pre-wrap", overflowY: "hidden" }}>
                                                        {item.content.how_apply}
                                                    </pre>
                                                </Container>

                                                {
                                                    item.image_how ?
                                                        <Container width="12" lg="6" px="16">
                                                            <img alt="how_img" src={imageURL(item.image_how)} width="100%" />
                                                            <Container mt="16" width="12" textAlign="center" fontSize="12px" fontColor="color-black" children={item.content.how_apply_alt} />
                                                        </Container>
                                                        : null
                                                }
                                            </Card>
                                        </Container>
                                    </ContainerFlex>

                                </ContainerFlex>
                                : null
                        }
                        <ContainerFlex width="12" justify="center" pv="18">
                            {
                                item && item.order < 4 ?
                                    <Button onClick={nextPill} flex width="auto" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="16" ph="32" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionNext" />} />
                                    : item ?
                                        <Fragment>
                                            <Button onClick={() => history.push(`/unit/${item.unit.id}/test`, { item: item.unit })} flex width="auto" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="16" ph="32" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionStartTest" />} />
                                            <Container width="12" ph="16" mt="16" mb="32" fontWeight="300" fontSize="18px" fontColor="color-black" textAlign="center" children={<TextLocalized children="msgStartTestSubtitle" />} />
                                        </Fragment>
                                        : null
                            }
                        </ContainerFlex>
                    </Fragment>
                    : null
            }
        </Container >
    );
};

export default PillDetail;