import { mdiRecordCircleOutline, mdiCheckboxBlankCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '../components/InputField';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import ThreadItem from '../components/ThreadItem';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';

const ThreadModal = ({ successCallback }) => {
    const [formData, setFormData] = useState({ title: "", text: "" });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.title && formData.text)
            HttpConnection(HttpOperations.threads, result => {
                dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                successCallback();
            }, error => console.log(error), HttpMethod.post, formData);
    }

    return <Card onClick={e => e.stopPropagation()} shadowOn width="10" lg="6" px="16" color="color-blue-light-a20" radius="12px">
        <Container width="wrap" fontColor="color-blue-dark" fontWeight="300" fontSize="48px" children={<TextLocalized children="actionNewThread" />} />
        <InputField label="msgTitle" name="title" value={formData.title} onChange={onChange} />
        <Card width="12" mt="24" border="solid" color="color-white" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
            <textarea onChange={onChange} name="text" value={formData.text} placeholder={dictionary["msgWriteText"]} rows="5" style={{ padding: "16px" }} />
        </Card>

        <ContainerFlex width="12" justify="flex-end" fontSize="14px">
            <Button onClick={sendRequest} pv="8" ph="16" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionSend" />} />
        </ContainerFlex>
    </Card>
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();
        let interval = setInterval(tick, delay);
        return () => clearInterval(interval);
    }, [delay])
}

const Forum = () => {
    const [threads, setThreads] = useState([]);
    const [announces, setAnnounces] = useState([]);
    const [announceIndex, setAnnounceIndex] = useState(0)

    const [filter, setFilter] = useState(1);
    const { dispatch } = useContext(UserContext);

    let history = useHistory();

    useInterval(() => {
        setAnnounceIndex(announceIndex + 1 < announces.length ? announceIndex + 1 : 0);
    }, 10000);

    useEffect(() => {
        HttpConnection(HttpOperations.announces, result => {
            setAnnounces(result.item)
        }, error => console.log(error), HttpMethod.get);
    }, [])

    useEffect(() => getItems(), [filter])

    const getItems = () => HttpConnection(`${HttpOperations.threads}/state/${filter}`, result => setThreads(result.item), error => console.log(error), HttpMethod.get);
    const showItem = (item) => history.push(`/forum/${item.id}`);

    const showModal = () => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ThreadModal successCallback={getItems} /> } })
    }

    return (
        <Container width="12" justify="center" minheight="100vh">
            {
                announces.length > 0 ?
                    <ContainerFlex width="12" minheight="100px" px="16" justify="center" textAlign="center" color="color-brown-light-a40" fontColor="color-brown-dark" fontWeight="300">
                        <Container width="12" lg="8" children={announces[announceIndex].text} />
                        <Container width="12" lg="8" fontWeight="normal" fontSize="12px" children={`- ${announces[announceIndex].owner.name} -`} />
                        <ContainerFlex width="12" lg="8" justify="center" fontWeight="normal" fontSize="12px">
                            {announces.map((item, index) => <Button key={index} onClick={() => setAnnounceIndex(index)} idealwidth="10px" height="10px" radius="50%" mv="8" mh="4" color={announceIndex === index ? "color-blue" : "color-blue-light"} hoverColor="color-blue-dark" />)}
                        </ContainerFlex>
                    </ContainerFlex>
                    : null
            }
            <ContainerFlex width="12" px="16" justify="center" >
                <ContainerFlex width="12" lg="8" pv="8" align="flex-end">
                    <Container width="wrap" fontColor="color-blue-dark" fontWeight="300" fontSize="48px" children={<TextLocalized children="msgThreads" />} />
                    <Button onClick={showModal} pv="4" ph="16" mh="8" mb="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionNewThread" />} />
                </ContainerFlex>
                <Card flex width="12" lg="8" px="16" mb="8" border="solid" borderWidth="1px" borderColor="color-blue" color="color-blue-light-a20" fontWeight="300">
                    <Button onClick={() => setFilter(1)} flex fontWeight={filter === 1 ? "300" : "normal"} fontColor={filter === 1 ? "color-blue-dark" : "color-blue-light"}>
                        <Icon style={{ margin: "0 4px 0 0" }} path={filter === 1 ? mdiRecordCircleOutline : mdiCheckboxBlankCircleOutline} size={1} />
                        <TextLocalized children="msgOpen" />
                    </Button>

                    <Button onClick={() => setFilter(2)} flex mh="8" ph="8" fontWeight={filter === 2 ? "300" : "normal"} fontColor={filter === 2 ? "color-blue-dark" : "color-blue-light"}>
                        <Icon style={{ margin: "0 4px 0 0" }} path={filter === 2 ? mdiRecordCircleOutline : mdiCheckboxBlankCircleOutline} size={1} />
                        <TextLocalized children="msgClose" />
                    </Button>
                </Card>

                <ContainerFlex width="12" lg="8" children={threads.map((item, index) => <ThreadItem key={index} item={item} showItem={showItem} />)} />
            </ContainerFlex>
        </Container>
    );
};

export default Forum;