const es = {
    msgAppName: "Woodigital",

    actionGetStart: "Empezar",
    actionDetails: "Detalles",
    actionLogin: "Acceder",
    actionRegister: "Inscribirse",
    actionLogout: "Salir",
    actionSend: "Enviar",
    actionGoTest: "Ir al test",
    actionSeeCert: "Ver certificado",
    actionNextPill: "Ir a la siguiente píldora",
    actionNewThread: "Nuevo hilo de conversación",
    actionBack: "Volver",
    actionComment: "Comentar",
    actionUpdate: "Actualizar",
    actionChangePass: "Cambiar contraseña",
    actionAddAnnouncement: "Añadir un anuncio",
    actionStartTest: "Empezar el test",
    actionSubmit: "Enviar",
    actionClose: "Cerrar",
    actionEdit: "Editar",
    actionDelete: "Borrar",
    actionShow: "Mostrar",
    actionNext: "Siguiente",
    actionDone: "Hecho",
    actionOpenResource: "Abrir recurso",
    actionDownloadInfo: "Descargar el manual de usuario",
    actionCourseCertificate: "Certificado del curso",

    msgLoremIpsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod placerat velit, quis vulputate urna tempus ac. Ut sit amet velit sagittis, tincidunt nibh eu, efficitur dolor.",
    msgLoremIpsumShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod placerat velit, quis vulputate urna tempus ac.",

    msgWelcome: "Bienvenido",
    msgBackToUnit: "Volver a la unidad de aprendizaje",
    msgExploreUnits: "Explora el curso de formación online WOODigital",
    msgExploreUnitsText: "La digitalización de la industria manufacturera, el desarrollo del big data, la producción de mobiliario a través de Internet y los materiales avanzados están transformando la industria de la madera y el mueble en Europa. Las empresas europeas están buscando trabajadores de la madera digitalmente competentes y familiarizados con las funcionalidades y los principios de la Industria 4.0. El curso de formación WOODigital ofrece una posibilidad única para  convertirse en un WOODWORKER 4.0 digitalmente competente.",
    msgLearningUnits: "Unidades de Aprendizaje",
    msgLatestPills: "Píldoras recomendadas",
    msgLatestPillsText: "Descrubre las píldoras formativas recomendadas por nuestros socios europeos.",

    msgPills: "Píldoras",
    msgPartners: "Socios",
    msgNextLearning: "Siguiente unidad de aprendizaje",

    msgExamAlert: "Al finalizar todas las píldoras de aprendizaje, los estudiantes deben completar una <b>autoevaluación de opción múltiple</b> para obtener <b>un certificado de finalización y una acreditación digital.</b> Se requiere <b>un resultado mínimo del 75%</b> para aprobar las Unidades de Aprendizaje.",
    msgAssignment: "Deberes",
    msgForStudents: "Para uso del Gestor",
    msgForTeachers: "Para uso del Profesor",
    msgProgressionOptions: "Opciones de progreso",
    msgCompetencesUnitTitle: "Al finalizar estas píldoras, los estudiantes deben ser capaces de:",

    msgCompetencesTitle: "Al finalizar estas píldoras, los estudiantes deben ser capaces de:",
    msgTopics: "Temas de interés",
    msgHowApply: "Aplicación de los conocimientos en la práctica",
    msgResources: "Recursos de aprendizaje",
    msgResourcesSubtitle: "Lea todos los recursos antes de pasar a la autoevaluación.",

    msgStartTestSubtitle: "**Al completar todas las píldoras de la Unidad de Aprendizaje**",


    msgWooLearningPlatform: "Plataforma de Aprendizaje WOODigital",
    msgWooLearningPlatformText: "La plataforma de formación WOODigital está dedicada a personas e instituciones interesadas en fomentar la innovación digital y beneficiarse de una oportunidad de formación de calidad.<br/><br/>La plataforma nutre a una generación de jóvenes europeos, estudiantes, profesionales, pero también a centros de FP, OTR y empresas, y les proporciona las habilidades y competencias que requiere el mercado laboral de la madera y el mueble en pleno proceso de digitalización transformadora. Recomendamos encarecidamente esta formación a:",

    msgStudents: "Estudiantes",
    msgEducators: "Docentes",
    msgCompanies: "Empresas y Asociaciones",
    msgProfessionals: "Profesionales / Desempleados",

    msgStudentsText: "Futuros trabajadores de la madera que, antes de entrar en el mercado laboral, desean adquirir las competencias digitales necesarias y asegurarse de que tienen conocimientos actualizados sobre la industria de la madera y el mueble en Europa.",
    msgEducatorsText: "Centros de FP y formativos que forman a los futuros trabajadores de la madera y que están interesados en contenidos de formación innovadores y de calidad.",
    msgCompaniesText: "Empresas del sector de la madera y el mueble dispuestas a mejorar las competencias digitales de sus empleados.",
    msgProfessionalsText: "Aquellos que buscan oportunidades para desarrollar habilidades profesionales específicas y/o descubrir nuevas trayectorias profesionales.",

    msgContact: "Contacto",
    msgHome: "Inicio",
    msgForum: "Foro",
    msgProfile: "Perfil",

    msgAnnouncements: "Anuncios",
    msgAnnouncementsFinished: "Anuncios Finalizados",

    msgThreads: "Conversaciones",
    msgOpen: "Abrir",
    msgClose: "Cerrar",
    msgLeaveComment: "Dejar un comentario",

    msgTeacherProfile: "Perfil de profesor",
    msgUserProfile: "Perfil de usuario",
    msgUserInfo: "Información para el usuario",

    msgRegisterText: "Regístrate y explora el curso de formación online WOODigital.",

    msgName: "Nombre y Apellido",
    msgEmail: "Email",
    msgCountry: "País",
    msgAge: "Edad",
    msgGender: "Género",
    msgOrganization: "Organización/Empresa",
    msgForgetPass: "¿Has olvidado tu contraseña?",

    msgPassword: "Contraseña",
    msgPassInfo: "Cambiar contraseña actual",
    msgCurrentPass: "Contraseña actual",
    msgNewPassword: "Nueva contraseña",

    msgNewAnnouncement: "Nuevo Anuncio",
    msgNewAnnouncementInfo: "Añadir un nuevo anuncio",
    msgAnnouncesInfo: "Mis anuncios",


    msgMyCertificates: "Mis certificados",
    msgMyCertificatesInfo: "Listado de todos mis certificados",

    msgDatenEnd: "Fecha de finalización",
    msgText: "Texto",
    msgWriteText: "Escribe tu texto",

    msgTitle: "Título",

    msgTerms: "Aceptar términos y condiciones",
    msgCopyright: "Copyright&copy;2021 WOODIGITAL. Todos los derechos reservados",

    msgProyectOwner: "UN PROYECTO DE",
    msgFooterContact: "CONTÁCTANOS",
    msgFooterNews: "NUESTRA NEWSLETTER",
    msgFooterLegal: "Aviso legal",
    msgFooterPrivacy: "Privacidad",
    msgFooterCookies: "Política de cookies",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
    msgFooterEu: "El apoyo de la Comisión Europea para la elaboración de esta publicación no constituye una aprobación de su contenido, que refleja únicamente la opinión de los autores. La Comisión no se hace responsable del uso que pueda hacerse de la información contenida en ella.",
    msgFooterGr: "Acuerdo de subvención: 2020-1-FR01-KA202-080104",
    msgCookies: "Esta Web utiliza cookies de terceros de publicidad comportamental propiedad de Google, persistentes durante 2 años, para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continúa navegando consideramos que está de acuerdo con su uso. Podrá revocar el consentimiento y obtener más información consultando nuestra Política de cookies.",
    msgFooterLinkedIn: 'GRUPO DE LINKEDIN',

    msgSendSuccess: "Éxito de la operación",

    errorOblData: "Rellene todos los campos obligatorios",
    errorLogin: "Email/contraseña incorrecta",
    errorRegister: "",
    errorSend: "Error de operación, por favor revise su información",

    msgNotPass: "No has superado esta prueba, por favor, vuelve a leer la lección e inténtelo de nuevo.",
    msgCongratulations: "¡Enhorabuena! Has superado esta prueba. Puedes encontrar tu certificado en la sección de Perfil.",

    /* PROFILE TYPES */
    msgStudent: "Estudiante",
    msgVetProvider: "Proveedor de FP",
    msgCompany: "Compañía",
    msgOther: "Otro",

    /* GENDER */
    msgFemale: "Femenino",
    msgMale: "Masculino",
    msgNonBinary: "No-binario",
    msgTransgender: "Transgénero",
    msgIntersex: "Intersexual",
    msgLetme: "Déjame escribir...",
    msgNotSay: "Prefiero no decirlo",

    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",


}

export default es;