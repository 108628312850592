
import { getUserToken, updateStorageUser, getStorageUser } from './UserUtil';

export const HttpOperations = {
    login: "auth",
    user: "user",
    units: "units",
    pills: "pills",
    threads: "threads",
    message: "message",
    announces: "announces",
    certificates: "certificates",
    docs: "docs",
    image: "image",
    contact: "contact",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        if ((request.status === 401) && !checkingAuth) {

            var auxUser = getStorageUser();

            if (auxUser) {
                const params = {
                    "username": auxUser.username,
                    "password": auxUser.password,
                }

                HttpConnection(HttpOperations.login, (item) => {
                    updateStorageUser(item);
                    HttpConnection(operation, success, error, method, data, false);
                },
                    (errorCode) => error(errorCode),
                    HttpMethod.post, params, true);
            }
        } else {
            error(request.status);
        }
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

//wifi: 192.168.5.39
//movil: 172.20.10.8
const debugMode = false;
export const serverUrlEndpoint = debugMode ? "http://192.168.5.39/cenfim/woodigital-api/public/api/" : "https://course.woodigital.eu/woodigital-api/public/api/";
export const imageURL = (id) => debugMode ? `http://192.168.5.39/cenfim/woodigital-api/public/api/image/${id}` : `https://course.woodigital.eu/woodigital-api/public/api/image/${id}`;
