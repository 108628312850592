import React, { useState, useContext } from 'react';

import spanish from '../../images/langs/spanish.png';
import english from '../../images/langs/english.png';
import french from '../../images/langs/fr.png';
import italian from '../../images/langs/it.png';
import hungarian from '../../images/langs/hu.jpg';



import es from '../../constants/es';
import en from '../../constants/en';
import fr from '../../constants/fr';
import it from '../../constants/it';
import hu from '../../constants/hu';

export const LanguageData = {
  lang: "langWoodigital"
}

export const dictionaryList = {
  es, en, fr, it, hu
};

export const languageOptions = [
  { id: 'es', text: 'Spanish', image: spanish },
  { id: 'en', text: 'English', image: english },
  { id: 'fr', text: 'French', image: french },
  { id: 'it', text: 'Italian', image: italian },
  { id: 'hu', text: 'Hungarian', image: hungarian },
];

export const langKey = (context, key) => `${key}${context.language.id === "es" ? "En" : "En"}`

const getCookieLang = () => {
  let lang = localStorage.getItem(LanguageData.lang);
  if (lang && languageOptions.filter(language => language.id == lang).length > 0) {
    const selectedLang = languageOptions.filter(language => language.id == lang)[0];
    return { language: selectedLang, dictionary: dictionaryList[selectedLang.id] };
  }

  return {
    language: navigator.language.includes("es") ? languageOptions[0] : languageOptions[1],
    dictionary: dictionaryList[navigator.language.includes("es") ? languageOptions[0].id : languageOptions[1].id]
  }
}

export const LanguageContext = React.createContext(getCookieLang());

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage); // it will update the language in state
      setDictionary(dictionaryList[selectedLanguage.id]);
    }
  };

  return <LanguageContext.Provider value={provider} children={props.children} />;
};