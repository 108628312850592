import React from 'react';
import { ContainerFlex, Container, Card } from './styled/Styled';

const TopicItem = ({ item, index }) => {
    return (
        <ContainerFlex multiline={false} width="12" lg="6" align="flex-start" px="16" >
            <Card minwidth="30px" idealwidth="30px" textAlign="center" border="solid" borderColor="color-white" borderWidth="1px" radius="10px" children={index + 1} />
            <Container ml="16" key={index} children={item.text} />
        </ContainerFlex>
    );
};

export default TopicItem;