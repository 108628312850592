import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import InputField from '../components/InputField';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';

import name from '../images/name.png'
import home from '../images/home.jpg';

const Login = () => {
    const [formData, setFormData] = useState({ email: "", password: "" })
    const { dispatch } = useContext(UserContext);
    let history = useHistory();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "") {
            HttpConnection(HttpOperations.login, response => {
                dispatch({ action: UserAction.login, data: response.item });
                history.replace("/");
            }, error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorLogin" } }), HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } });
        }
    }

    return (
        <ContainerFlex width="12" justify="center" height="calc(100vh - 64px)" image={home}>
            <ContainerFlex width="12" height="100%" pv="32" justify="center" align="center" color="color-blue-light-a50">
                <ContainerFlex width="12" lg="8" height="100%" justify="center" align="center" >
                    <Card width="12" lg="6" justify="center" shadowOn color="color-white" px="16" radius="20px">
                        <ContainerFlex width="12" justify="center" align="center" children={<img alt="logo" src={name} height="24px" />} />
                        <ContainerFlex width="12" justify="center" align="center" children={<TextLocalized children="msgWelcome" />} />

                        <InputField label="msgEmail" name="email" value={formData.email} onChange={onChange} />
                        <InputField type="password" label="msgPassword" name="password" value={formData.password} onChange={onChange} />

                        <Button mv="24" textAlign="center" fontColor="color-blue-dark" fontWeight="300" fontSize="12px" children={<TextLocalized children="msgForgetPass" />} />

                        <Button onClick={sendRequest} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionLogin" />} />
                    </Card>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Login;