import React from 'react';
import { useContext, useEffect, useState } from 'react';

import { UserContext } from './provider/UserProvider';
import { Button, Card, Container, TextLocalized } from './styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, imageURL } from './util/HttpConnection';


const PillItem = ({ item, showItem }) => {

    const [color, setColor] = useState();
    const { state } = useContext(UserContext);

    useEffect(() => {
        if (item.done) setColor(item.done.length > 0 ? "color-green" : "color-black-a40")
        else setColor("color-black-a40")
    }, [item])

    const toggleDone = () => {
        setColor(color === "color-green" ? "color-black-a40" : "color-green");
        HttpConnection(`${HttpOperations.pills}/${item.id}/done`,
            responde => { }, error => console.log(error), HttpMethod.post);
    }

    return (
        <Container width="12" lg="3" px="8">
            <Card flex color="color-white" height="100%" border="solid" borderWidth="1px" borderColor="color-blue" justify="center">
                <Card flex width="12" align="flex-start" justify="flex-end" height="200px" fontColor="color-blue-dark" px="8" image={imageURL(item.image)}>
                    {
                        state.login && item.done ?
                            <Button onClick={toggleDone} flex shadowOn align="center" color="color-white" radius="20px" px="8">
                                <Card cursor="pointer" minwidth="18px" height="18px" radius="50%" border="solid" borderColor="color-black" borderWidth="1px" color={color} />
                                <Container cursor="pointer" ph="8" fontWeight="bold" children={<TextLocalized children="actionDone" />} />
                            </Button>
                            : null
                    }
                </Card>
                <Container width="12" textAlign="center" px="8" fontWeight="bold" fontSize="18px" children={`${item.content.title ?? ""}`} />
                <Container width="12" textAlign="center" ph="8" fontSize="14px" children={item.content.description} />

                <Button onClick={showItem} mv="8" ph="16" pv="4" color="color-blue-light" fontColor="color-white" radius="20px" children={<TextLocalized children="actionGetStart" />} />
            </Card>
        </Container>
    );
};

export default PillItem;