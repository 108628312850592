import React from 'react';
import { Button, Card, Container, TextLocalized } from './styled/Styled';
import { imageURL } from './util/HttpConnection';

const LearningUnit = ({ item, showItem }) => {
    return (
        <Container width="12" lg="4" px="8">
            <Card flex shadowOn height="100%" color="color-white" justify="center">
                <Card flex width="12" align="center" height="200px" fontColor="color-blue-dark" ph="8" justify="center" image={imageURL(item.image)} />
                <Container width="12" textAlign="center" px="8" fontWeight="bold" fontSize="18px" children={`${item.content.title ?? ""}`} />
                <Container width="12" textAlign="center" ph="8" fontSize="14px" children={item.content.resume} />

                <Button onClick={showItem} mv="16" ph="16" pv="4" color="color-blue-dark" fontColor="color-white" radius="20px" children={<TextLocalized children="actionGetStart" />} />
            </Card>
        </Container>
    );
};

export default LearningUnit;