import Icon from '@mdi/react';
import { mdiCommentOutline, mdiSchoolOutline } from '@mdi/js';

import React from 'react';
import { Button, Container, ContainerFlex } from './styled/Styled';
import { format } from 'date-fns';

const ThreadItem = ({ item, showItem }) => {
    return (
        <Button onClick={() => showItem(item)} flex width="12" mv="8" px="8" align="flex-start" border="solid" borderColor="color-blue-dark" borderWidth="1px">
            <Container ph="8" width="wrap">
                <Container fontSize="18px" fontWeight="300" children={item.title} />
                <ContainerFlex align="center">
                    <Container fontSize="10px" fontWeight="100" children={item.owner.name} />
                    {
                        item.owner.role.id === 3
                            ? <Icon style={{ margin: "0 8px" }} path={mdiSchoolOutline} size={"12px"} />
                            : null
                    }
                    <Container fontSize="10px" fontWeight="100" children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                </ContainerFlex>
            </Container>
            <ContainerFlex align="center">
                <Icon style={{ margin: "0 8px" }} path={mdiCommentOutline} size={1} />
                <Container fontSize="12px" children={item.num_messages} />
            </ContainerFlex>

        </Button>
    );
};

export default ThreadItem;