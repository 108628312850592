import React from 'react';
import { Card, Container } from './styled/Styled';

const MessageItem = ({item}) => {
    return (
        <Card width="12" mv="8" border="solid" borderColor="color-blue-dark" borderWidth="1px">
            <Card px="16" fontSize="14px" radiusbl="0" radiusbr="0" color="color-blue-light-a20" children={`${item.owner.name} commented`} />
            <Container width="12" height="1px" color="color-blue-dark" />
            <Container px="16" children={item.text} />
        </Card>
    );
};

export default MessageItem;