import React, { useContext } from 'react';
import { LanguageContext } from './provider/LanguageProvider';
import { Card, Container, TextLocalized } from './styled/Styled';

const InputField = ({ type = "text", label, name, value, onChange }) => {
    const languageContext = useContext(LanguageContext);
    return <Container width="12" mv="8">
        <Container mb="8" fontColor="color-blue-dark" fontWeight="300" children={<TextLocalized children={label} />} />
        <Card color="color-white" width="12" border="solid" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
            <input type={type} onChange={onChange} name={name} value={value} placeholder={languageContext.dictionary[label]} rows="5" style={{ padding: "8px 16px" }} />
        </Card>
    </Container>
}

export const InputSelectField = ({ label, name, value, onChange, options = [1, 2, 3] }) => {
    const { dictionary } = useContext(LanguageContext);

    return <Container width="12" mv="8">
        <Container mb="8" fontColor="color-blue-dark" fontWeight="300" children={<TextLocalized children={label} />} />
        <Card color="color-white" width="12" border="solid" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
            <select style={{ padding: "11px 16px" }} onChange={onChange} name={name} value={value}>
                <option value="" children={dictionary[label]} />
                {options.map((opt, index) => <option key={index} value={dictionary[opt]} children={dictionary[opt]} />)}
            </select>
        </Card>
    </Container>
}

export default InputField;