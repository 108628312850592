const fr = {
    msgAppName: "Woodigital",

    actionGetStart: "Commencer",
    actionDetails: "Détails",
    actionLogin: "Se connecter",
    actionRegister: "S'inscrire",
    actionLogout: "Déconnexion",
    actionSend: "Envoyer",
    actionGoTest: "Passer au test",
    actionSeeCert: "Voir certificat",
    actionNextPill: "Aller à la prochaine unité",
    actionNewThread: "Nouveau fil de discussion",
    actionBack: "Revenir",
    actionComment: "Commenter",
    actionUpdate: "Mettre à jour",
    actionChangePass: "Changer le mot de passe",
    actionAddAnnouncement: "Ajouter une annonce",
    actionStartTest: "Commencer le test",
    actionSubmit: "Soumettre",
    actionClose: "Fermer",
    actionEdit: "Modifier",
    actionDelete: "Effacer",
    actionShow: "Montrer",
    actionNext: "Suivant",
    actionDone: "Fait",
    actionOpenResource: "Ressource ouverte",
    actionDownloadInfo: "Télécharger le mode d'emploi",
    actionCourseCertificate: "Certificat de cours",

    msgWelcome: "Bienvenue",
    msgBackToUnit: "Retour au module d'apprentissage",
    msgExploreUnits: "Explorer la formation de WOODigital en ligne",
    msgExploreUnitsText: "La digitilisation en cours du secteur de la manufacture, le développement du big data, la production du travail du bois et des meubles connectée à Internet et les matériaux de pointe sont aujourd'hui en train de restructurer les industries du bois et du meuble à travers l'Europe. Les entreprises européennes sont à la recherche de menuisiers compétent sur le numérique, habitué aux fonctionnalités et aux principes de l'industrie 4.0. La formation de WOODigital offre une possibilité unique d'amélioration des compétences, vous permettant de devenir un menuisier compétent 4.0.",
    msgLearningUnits: "Modules d'apprentissage",
    msgLatestPills: "Unités recommandées",
    msgLatestPillsText: "Découvrir les unités d'apprentissage recommandées par nos partenaires européens.",

    msgPills: "Modules",
    msgPartners: "Partenaires ",
    msgNextLearning: "Prochains modules d'apprentissage",

    msgExamAlert: "A la fin de chaque unité d'apprentissage, les étudiants doivent remplir une auto-évaluation à choix multiple afin d'obtenir un certificat d'achèvement et un badge numérique. Un résultat minimal de 75% est requis pour réussir le module d'apprentissage.",
    msgAssignment: "Evaluation",
    msgForStudents: "A l'intention des managers",
    msgForTeachers: "A l'intention des enseignants",
    msgProgressionOptions: "Options de progression",
    msgCompetencesUnitTitle: "A la fin de ces unités, les étudiants devraient être capabaple de:",

    msgCompetencesTitle: "A la fin de ces unités, les étudiants devraient être capabaple de:",
    msgTopics: "Thèmes",
    msgHowApply: "Application des connaissances en pratique",
    msgResources: "Ressources d'apprentissage",
    msgResourcesSubtitle: "Lisez toutes les ressources suivantes avant de passer à l'auto-évaluation.",

    msgStartTestSubtitle: "**A la fin de toutes les unités d'apprentissage du module d'apprentissage**",


    msgWooLearningPlatform: "La plateforme d'apprentissage WOODigital",
    msgWooLearningPlatformText: "La plateforme d'apprentissage de WOODigital est destinée aux personnes et aux institutions souhaitant favoriser l'innovation numérique et bénéficier de opportunité d'une formation de qualité.<br/><br/>La plateforme contribue à la formation d'une génération de jeunes européens, étudiants, professionnels mais aussi des établissements de formation professionnelle, organisations de recherche et de formation et entreprises, et leur fournit des qualifications et des compétences requises dans le marché du bois et du meuble, qui subit un processus de numérisation transformative. Nous recommendons fortement cette formation pour:",

    msgStudents: "Etudiants",
    msgEducators: "Educateurs",
    msgCompanies: "Entreprises et associations",
    msgProfessionals: "Professionnels/Sans emploi",

    msgStudentsText: "Les futurs menuisiers qui, avant d'entrer sur le marché du travail, souhaitent acquérir des compétences numériques nécessaires et s'assurer qu'ils ont une conaissance actuelle de l'industrie du meuble et du bois en Europe.",
    msgEducatorsText: "LEs établissements de formation qui éduquent les futurs menuisiers and qui sont intéressés par un contenu de formation innovant et qualitatif.",
    msgCompaniesText: "Les entreprises de bois et de meubles souhaitant améliorer les compétences digitales de leurs employés.",
    msgProfessionalsText: "Ceux qui cherchent des opportunités de développer des compétences professionnelles spécifiques et/ou à découvrir des nouvelles possibilités de carrière.",

    msgContact: "Contact",
    msgHome: "Accueil",
    msgForum: "Forum",
    msgProfile: "Profil",

    msgAnnouncements: "Annonces",
    msgAnnouncementsFinished: "Annonces complètes",

    msgThreads: "Forum",
    msgOpen: "Ouvrir",
    msgClose: "Fermer",
    msgLeaveComment: "Laisser un commentaire",

    msgTeacherProfile: "Profil enseignant",
    msgUserProfile: "Profil utilisateur",
    msgUserInfo: "Information de l'utilisateur",

    msgRegisterText: "S'inscrire et explorer la formation WOODigital en ligne.",

    msgName: "Nom et nom de famille",
    msgEmail: "Email",
    msgCountry: "Pays",
    msgAge: "Age",
    msgGender: "Sexe",
    msgOrganization: "Organisation",
    msgForgetPass: "Mot de passe oublié?",

    msgPassword: "Mot de passe",
    msgPassInfo: "Changer votre mot de passe actuel",
    msgCurrentPass: "Mot de passe actuel",
    msgNewPassword: "Nouveau mot de passe",

    msgNewAnnouncement: "Nouvelle annonce",
    msgNewAnnouncementInfo: "Ajouter une nouvelle annonce",
    msgAnnouncesInfo: "Liste de mes annonces",


    msgMyCertificates: "Mes certificats",
    msgMyCertificatesInfo: "Liste de tous mes certificats ",

    msgDatenEnd: "Date de fin",
    msgText: "Texte",
    msgWriteText: "Ecrivez votre texte",

    msgTitle: "Titre",

    msgTerms: "Accepter les conditions générales",
    msgCopyright: "Copyright&copy;2021 WOODIGITAL. Tous droits réservés",

    msgProyectOwner: "UN PROJET DE",
    msgFooterContact: "NOUS CONTACTER",
    msgFooterNews: "NOTRE NEWSLETTER",
    msgFooterLegal: "Avis juridique",
    msgFooterPrivacy: "Confidentialité",
    msgFooterCookies: "Politique des cookies",
    msgFooterEu: "Le soutien de la Commission européenne à la production de cette publication ne constitue pas une approbation du contenu, qui ne reflète que l’opinion des auteurs, et la Commission ne peut être tenue responsable de l’usage qui pourrait être fait des informations qu’elle contient.",
    msgFooterGr: "Accord de subvention: 2020-1-FR01-KA202-080104",
    msgFooterLinkedIn: 'GROUPE LINKEDIN',

    msgSendSuccess: "Succès de l'opération",

    errorOblData: "Veuillez remplir toutes les informations requises",
    errorLogin: "Email/mot de passe incorrect",
    errorRegister: "",
    errorSend: "Echec de l'opération, veuillez vérifier vos informations",

    msgNotPass: "Vous n'avez pas réussi ce test. Veuillez relire la leçon et essayer à nouveau.",
    msgCongratulations: "Félicitations !! Vous avez réussi le test. Vous pouvez trouver votre certificat dans la section Profil.",

    /* PROFILE TYPES */
    msgStudent: "Etudiant",
    msgVetProvider: "Etablissement de formation professionnelle fournisseur",
    msgCompany: "Entreprise",
    msgOther: "Autre",

    /* GENDER */
    msgFemale: "Femme",
    msgMale: "Homme",
    msgNonBinary: "Non-binaire",
    msgTransgender: "Transgenre",
    msgIntersex: "Intersexe",
    msgLetme: "Laissez moi écrire...",
    msgNotSay: "Je préfère ne pas le dire",

    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default fr;