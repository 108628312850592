import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, imageURL } from '../components/util/HttpConnection';

const SingleAnswer = ({ item, updateAnswer }) => {

    const onChange = (e) => updateAnswer(parseInt(e.target.value));

    return <Container width="12" ph="8" pv="16" fontColor="color-black">
        {
            item.answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="8" align="center">
                <input type="radio"
                    checked={answer.selected}
                    onChange={onChange}
                    value={answer.id} name={item.id} />
                <Container width="wrap" ph="8" children={answer.text} />
            </ContainerFlex>)
        }
    </Container>
}

const QuizItem = ({ item, updateQuiz }) => {
    const updateAnswer = (id) => updateQuiz(item.id, id);

    return <Card width="12" mt="16"
        border="solid" borderColor={"color-black"} borderWidth="1px" color={"color-white"}>
        <Card px="8" radius="0" radiustl="3px" radiustr="3px" color={"color-blue"} width="12" fontSize="16px" fontWeight="300" fontColor="color-white" children={item.text} />
        <SingleAnswer item={item} updateAnswer={updateAnswer} />
    </Card>
}

const LearningUnitTest = () => {
    const [item, setItem] = useState();
    const [quizs, setQuizs] = useState([]);

    const { dispatch } = useContext(UserContext);
    const { language } = useContext(LanguageContext);

    let params = useParams()

    useEffect(() => {
        HttpConnection(`${HttpOperations.units}/${params.id}?lang=${language.id}`, result => {
            setItem(result.item);
        }, error => console.log(error), HttpMethod.get)

        HttpConnection(`${HttpOperations.units}/${params.id}/test?lang=${language.id}`, result => {
            let quizAux = result.item.quizs;
            quizAux.forEach(quiz => quiz.answers.map(answer => answer.selected = false));
            setQuizs(quizAux);
        }, error => console.log(error), HttpMethod.get)
    }, [params.id, language])

    const sendRequest = () => {
        let answerSelected = [];
        //Add answers to array
        quizs.forEach(quiz => quiz.answers.forEach(answer => {
            if (answer.selected)
                answerSelected.push(answer)
        }));

        //Send responses
        HttpConnection(`${HttpOperations.units}/${item.id}/test?lang=${language.id}`, result => {
            if (result.item == true) {
                //Show congratulations
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgCongratulations" } })
            } else {
                //Show read resources again
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "msgNotPass" } })
            }
        }, error => console.log(error), HttpMethod.patch, answerSelected)
    }

    const updateQuiz = (quizId, answerId) => {
        let auxQuiz = [...quizs];
        auxQuiz.forEach(quiz => {
            if (quiz.id === quizId)
                quiz.answers.forEach(answer => {
                    answer.selected = answer.id === answerId
                })
        })
        setQuizs(auxQuiz);
    }

    return (
        <Container width="12" minheight="calc(100vh - 64px)">
            {item ?
                <ContainerFlex width="12" justify="center">
                    <ContainerFlex width="12" lg="8" pv="32" justify="center" align="center">
                        <Container width="12" lg="6">
                            <Container width="12" ph="16" fontWeight="bold" fontSize="17px" fontColor="color-black" children={"Self-assessment"} />
                            <Container width="12" ph="16" fontWeight="bold" fontSize="74px" fontColor="color-black" children={item && item.content ? item.content.title : ""} />
                            <Container width="12" ph="16" pv="16" fontWeight="300" fontSize="18px" fontColor="color-black" children={<TextLocalized children="msgExamAlert" />} />
                        </Container>
                        <ContainerFlex width="0" lg="6" justify="flex-end" align="cener">
                            <Card idealwidth="300px" height="300px" image={imageURL(item.image)} radius="50%" />
                        </ContainerFlex>
                        <ContainerFlex width="12" ph="16" children={quizs.map((item, index) => <QuizItem key={index} item={item} updateQuiz={updateQuiz} />)} />
                    </ContainerFlex>

                    <ContainerFlex width="12" justify="center" pv="18">
                        <Button onClick={sendRequest} flex width="auto" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="16" ph="32" color="color-blue" fontColor="color-white" children={<TextLocalized children="actionSubmit" />} />
                    </ContainerFlex>
                </ContainerFlex>
                : null}
        </Container >
    );
};

export default LearningUnitTest;