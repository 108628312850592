const en = {
    msgAppName: "Woodigital",

    actionGetStart: "Get started",
    actionDetails: "Details",
    actionLogin: "Sign in",
    actionRegister: "Sign up",
    actionLogout: "Logout",
    actionSend: "Send",
    actionGoTest: "Go to test",
    actionSeeCert: "See certificated",
    actionNextPill: "Go to next pill",
    actionNewThread: "New thread",
    actionBack: "Go back",
    actionComment: "Comment",
    actionUpdate: "Update",
    actionChangePass: "Change password",
    actionAddAnnouncement: "Add announcement",
    actionStartTest: "Start test",
    actionSubmit: "Submit",
    actionClose: "Close",
    actionEdit: "Edit",
    actionDelete: "Delete",
    actionShow: "Show",
    actionNext: "Next",
    actionDone: "Done",
    actionOpenResource: "Open resource",
    actionDownloadInfo: "Download the user manual",
    actionCourseCertificate: "Course certificate",

    msgLoremIpsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod placerat velit, quis vulputate urna tempus ac. Ut sit amet velit sagittis, tincidunt nibh eu, efficitur dolor.",
    msgLoremIpsumShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod placerat velit, quis vulputate urna tempus ac.",

    msgWelcome: "Welcome",
    msgBackToUnit: "Back to learning unit",
    msgExploreUnits: "Explore the WOODigital on-line training course",
    msgExploreUnitsText: "The ongoing digitalization of the manufacturing industry, development of big data, production of woodworking and furniture pieces connected to the Internet and advanced materials are actively reshaping today the wood and furniture industries across Europe. European companies are searching for digitally competent woodworkers familiar with Industry 4.0 functionalities and principles. The WOODigital training course offers unique upskilling possibility allowing you to become one of the digitally competent WOODWORKER 4.0.",
    msgLearningUnits: "Learning Units",
    msgLatestPills: "Recommended pills",
    msgLatestPillsText: "Discover training pills recommended by our European Partners. ",

    msgPills: "Pills",
    msgPartners: "Partners",
    msgNextLearning: "Next learning units",

    msgExamAlert: "On completion of all the learning pills, students must complete a <b>multiple-choice self-assessment</b> in order to obtain a completion <b>certificate and digital badge.</b> A minimum <b>result of 75%</b> is required to pass the Learning Unit.",
    msgAssignment: "Assignment",
    msgForStudents: "For use by a Manager",
    msgForTeachers: "For use by a Teacher",
    msgProgressionOptions: "Progression options",
    msgCompetencesUnitTitle: "On completion of these pills, the students should be able to:",

    msgCompetencesTitle: "On completion of this pill, the students should be able to:",
    msgTopics: "Topics",
    msgHowApply: "Application of knowledge in practice",
    msgResources: "Learning resources",
    msgResourcesSubtitle: "Read all of the following resources before progressing to the self-assessment.",

    msgStartTestSubtitle: "**On completion of all the learning pills of the Learning Unit**",


    msgWooLearningPlatform: "WOODigital Learning Platform",
    msgWooLearningPlatformText: "The WOODigital training platform is dedicated to persons and institutions interested in fostering digital innovation and benefit from a quality training opportunity.<br/><br/>The platform nurtures a generation of young Europeans, students, professionals, but also VET centres, RTOs and companies, and provide them with skills and competences required by the wood and furniture job market undergoing a transformative digitization process. We strongly recommend this training to:",

    msgStudents: "Students",
    msgEducators: "Educators",
    msgCompanies: "Companies & Associations",
    msgProfessionals: "Professionals / Unemployed",

    msgStudentsText: "Future woodworkers that before entering the labor market wish to acquire necessary digital skills and ensure that they have up-to-date knowledge of the wood and furniture industry in Europe.",
    msgEducatorsText: "VET and training centers educating future woodworkers and interested in innovative and qualitative training content.",
    msgCompaniesText: "Wood and furniture companies willing to upskill the digital competences of its employees.",
    msgProfessionalsText: "Those looking for opportunities to develop specific professional skills and/or discover new career paths.",

    msgContact: "Contact",
    msgHome: "Home",
    msgForum: "Forum",
    msgProfile: "Profile",

    msgAnnouncements: "Announcements",
    msgAnnouncementsFinished: "Finished Announcements",

    msgThreads: "Threads",
    msgOpen: "Open",
    msgClose: "Close",
    msgLeaveComment: "Leave a comment",

    msgTeacherProfile: "Teacher Profile",
    msgUserProfile: "User Profile",
    msgUserInfo: "User information",

    msgRegisterText: "Register and explore the WOODigital online training course.",

    msgName: "Name and Surname",
    msgEmail: "Email",
    msgCountry: "Country",
    msgAge: "Age",
    msgGender: "Gender",
    msgOrganization: "Organization",
    msgForgetPass: "Forget your password?",

    msgPassword: "Password",
    msgPassInfo: "Change your current password",
    msgCurrentPass: "Current password",
    msgNewPassword: "New password",

    msgNewAnnouncement: "New Announcement",
    msgNewAnnouncementInfo: "Add a new announcement",
    msgAnnouncesInfo: "List of my announcements",


    msgMyCertificates: "My certificates",
    msgMyCertificatesInfo: "List of all my certificates",

    msgDatenEnd: "Date end",
    msgText: "Text",
    msgWriteText: "Write your text",

    msgTitle: "Title",

    msgTerms: "Accept terms & conditions",
    msgCopyright: "Copyright&copy;2021 WOODIGITAL. All rights reserved",

    msgProyectOwner: "A PROJECT OF",
    msgFooterContact: "CONTACT US",
    msgFooterNews: "OUR NEWSLETTER",
    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
    msgFooterEu: "The European Commission's support for the production of this publication does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
    msgFooterGr: "Grant Agreement: 2020-1-FR01-KA202-080104",
    msgCookies: "Esta Web utiliza cookies de terceros de publicidad comportamental propiedad de Google, persistentes durante 2 años, para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continúa navegando consideramos que está de acuerdo con su uso. Podrá revocar el consentimiento y obtener más información consultando nuestra Política de cookies.",
    msgFooterLinkedIn: 'LINKEDIN GROUP',

    msgSendSuccess: "Operation success",

    errorOblData: "Fill all required fields",
    errorLogin: "Email/password incorrect",
    errorRegister: "",
    errorSend: "Operation error, please check your information",

    msgNotPass: "You have not passed this test, please re-read the lesson and try again.",
    msgCongratulations: "Congratulations !! You have passed this test. You can find your certificate in Profile's section.",

    /* PROFILE TYPES */
    msgStudent: "Student",
    msgVetProvider: "VET Provider",
    msgCompany: "Company",
    msgOther: "Other",

    /* GENDER */
    msgFemale: "Female",
    msgMale: "Male",
    msgNonBinary: "Non-binary",
    msgTransgender: "Transgender",
    msgIntersex: "Intersex",
    msgLetme: "Let me type…",
    msgNotSay: "I prefer not to say",

    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",

}

export default en;